import {Manager_DiseaseProfile} from '@app/dp/frontend';
import * as React from 'react';
import {AppToolsScreen} from '@nu-art/thunderstorm/frontend';

class PgDev_DPManager_Class extends React.Component {
	render() {
		return <Manager_DiseaseProfile diseaseProfileId={'697caca28e03a4f28e5424e1bbae6752'}/>;
	}
}

export const PgDev_DPManager: AppToolsScreen = {
	name: 'Disease Profile Manager',
	renderer: PgDev_DPManager_Class,
	key: 'disease-profile-manager',
};