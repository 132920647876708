import * as React from 'react';
import {AppToolsScreen, ComponentSync, LL_V_L, ModuleFE_Notifications, TS_Button} from '@nu-art/thunderstorm/frontend';
import './PgDev_Notifications.scss';

type State = {};


class PgDev_Notifications_Class
	extends ComponentSync<{}, State> {

	protected deriveStateFromProps(nextProps: any): State {
		return {};
	}

	private postNotification = () => {
		const randomNumber = Math.floor(Math.random() * 100 + 1);
		ModuleFE_Notifications.create().content('Test Title', `This is a test ${randomNumber}`).show(3000);
	};

	private showAllNotifications = () => {
		ModuleFE_Notifications.showAllNotifications();
	};

	private postThreeNotifications = () => {
		for (let i = 0; i < 3; i++) {
			setTimeout(() => {
				this.postNotification();
			}, i * 500);
		}
	};

	render() {
		return <LL_V_L style={{padding: '20px', gap: '10px'}}>
			<TS_Button onClick={this.postNotification}>Post Notification</TS_Button>
			<TS_Button onClick={this.postThreeNotifications}>Post 3 Notifications</TS_Button>
			<TS_Button onClick={this.showAllNotifications}>Show All Notification</TS_Button>
			<TS_Button onClick={() => this.logInfo('HELLO')}>Say Hello</TS_Button>
		</LL_V_L>;
	}
}

export const PgDev_Notifications: AppToolsScreen = {name: 'Notifications', key: 'notifications', renderer: PgDev_Notifications_Class};