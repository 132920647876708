import * as React from 'react';
import './Component_SideNavBar.scss';
import {_className, ComponentSync, ModuleFE_Routing, OnStorageKeyChangedListener, openContent, TS_NavLink} from '@nu-art/thunderstorm/frontend';
import {ICONS} from '@app/styles';
import {ModuleFE_AppModule, RouteDomainDeclaration, RouteDomainsMap, ToggleSideNavBarStateListener} from '@modules/app-module/ModuleFE_AppModule';
import {_values} from '@nu-art/ts-common';
import {StorageKey_SessionId} from '@nu-art/user-account/frontend';


type Props = {}

type State = {
	sideNavBarIsOpen: boolean;
	routeKey?: string;
}

export class Component_SideNavBar
	extends ComponentSync<Props, State>
	implements ToggleSideNavBarStateListener, OnStorageKeyChangedListener {

	constructor(p: Props) {
		super(p);
	}

	deriveStateFromProps() {
		return {
			routeKey: ModuleFE_Routing.getMyRouteKey(),
			sideNavBarIsOpen: ModuleFE_AppModule.getNavBarStatus()
		};
	}

	__onStorageKeyEvent(event: StorageEvent) {
		if (event.key !== StorageKey_SessionId.key)
			return;

		this.forceUpdate();
	}

	__onToggleSideNavBarState() {
		this.setState({...this.state, sideNavBarIsOpen: ModuleFE_AppModule.getNavBarStatus()});
	}

	render() {
		return <div
			className={_className('knowledge-manager__side-nav-bar unselectable', this.state.sideNavBarIsOpen ? 'open' : undefined)}>
			{_values(RouteDomainsMap)
				.filter(route => route.route.enabled?.() ?? true)
				.map((item) => {
					return this.renderNavBarItem(item);
				})}
		</div>;
	}

	private renderNavBarItem(item: RouteDomainDeclaration) {
		const Icon = ICONS[item.icon];
		return <TS_NavLink
			key={item.route.key}
			route={item.route}
			ignoreClickOnSameRoute={true}
			className={({isActive}) =>
				_className(
					'side-nav-bar__nav-item',
					isActive ? 'selected' : undefined,
					item.designator && 'show-designator'
				)}
			{...(!this.state.sideNavBarIsOpen && openContent.tooltip.right('nav-bar-label-tooltip', () => item.routeTitle, {offset: 10}))}>
			<Icon.component data-designator={item.designator}/>
			<div className={'side-nav-bar__nav-item__label'}>{item.routeTitle}</div>
		</TS_NavLink>;
	}
}