import * as React from 'react';
import './ThemeTest.scss';
import {AppToolsScreen, HeightBounder, LL_H_C} from '@nu-art/thunderstorm/frontend';
import {StaticLogger} from '@nu-art/ts-common';
import QColorPicker from '@app/hcs/frontend/ui/q-components/QColorPicker';
import {AppTheme, AppThemeType, ModuleFE_Theme} from '@app/styles';

const toggleThemeOption = <T extends keyof AppThemeType>(option: T) => {
	const currentProp: AppThemeType[T] = ModuleFE_Theme.getThemeProp<T>(option);
	const themeOptions = AppTheme[option] as AppThemeType[T][];
	let index = themeOptions.indexOf(currentProp) + 1;
	if (index >= themeOptions.length)
		index = 0;
	ModuleFE_Theme.setThemeProp(option, themeOptions[index]);
};

const ThemeTest: React.FC = () => {

	const onClick = (name: string) => {
		StaticLogger.logInfo('clicked', name);
	};

	const onBlur = (e: React.FocusEvent<HTMLDivElement, Element>, name: string) => {
		e.nativeEvent.stopImmediatePropagation();
		StaticLogger.logInfo('losing focus:', name);
		return false;
	};

	return <HeightBounder>
		<LL_H_C className={'theme-test'}>
			<div className={'theme-toggle-button'} onClick={() => {
				toggleThemeOption('theme');
			}}>T
			</div>
			<div className={'device-toggle-button'} onClick={() => {
				toggleThemeOption('device');
			}}>D
			</div>
			<div>Focus test!</div>
			<div style={{width: '400px', height: '400px', background: 'cyan'}} tabIndex={1}>
				{/*@ts-ignore*/}
				<div style={{width: '100px', height: '100px', background: 'green'}} tabIndex={1} path={'green'}
						 onClick={() => {
							 onClick('green');
						 }}
						 onBlur={(e) => {
							 onBlur(e, 'green');
						 }}/>
				{/*@ts-ignore*/}
				<div style={{width: '100px', height: '100px', background: 'red'}} tabIndex={1} path={'red'}
						 onClick={() => {
							 onClick('red');
						 }}
						 onBlur={(e) => {
							 onBlur(e, 'red');
						 }}/>
			</div>
			<QColorPicker/>
		</LL_H_C>

	</HeightBounder>;
};

export const PgDev_ThemeTest: AppToolsScreen = {name: 'Theme Test', key: 'theme-test', renderer: ThemeTest};