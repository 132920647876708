import * as React from 'react';
import {
    _className,
    ComponentSync,
    Model_PopUp,
    ModuleFE_MouseInteractivity,
    mouseInteractivity_ToolTip,
    openContent,
    TS_Table
} from '@nu-art/thunderstorm/frontend';
import {
    ModuleFE_PermissionAccessLevel,
    ModuleFE_PermissionDomain,
    ModuleFE_PermissionGroup
} from '@nu-art/permissions/frontend';
import './Component_UserTypeInfo.scss';
import {PermissionGroupData, PermissionGroupData_All} from '@app/shared/permission-groups';
import {_keys, filterDuplicates, flatArray, MUSTNeverHappenException} from '@nu-art/ts-common';
import {UserTypeType} from '@app/org/_entity/user/shared';
import {Const__ProjectName_KnowledgeManager} from '@app/hcs';

const scopeNameMap: { [k in UserTypeType]: string } = {
    knowledgeManager: Const__ProjectName_KnowledgeManager,
    advisor: 'Advisor',
};

type Props = {
    scope: UserTypeType
}

type State = {
    data: PermissionGroupData[];
}

const userTypeCol = 'User Type';

export class Component_UserTypeInfo
    extends ComponentSync<Props, State> {

    // ######################## Lifecycle ########################

    protected deriveStateFromProps(nextProps: Props, state: State) {
        state.data = PermissionGroupData_All;
        return state;
    }

    // ######################## Logic ########################

    private openPopUp = () => {
        ModuleFE_MouseInteractivity.hide(mouseInteractivity_ToolTip);

        const model: Model_PopUp = {
            id: 'user-types-popup',
            content: this.renderPopUpContent,
            originPos: {x: window.innerWidth / 2, y: window.innerHeight / 2},
            modalPos: {x: 0, y: 0},
        };

        ModuleFE_MouseInteractivity.showContent(model);
    };

    // ######################## Render ########################

    private renderToolTipContent = () => {
        return <>Click to view table for user types under the <b>{scopeNameMap[this.props.scope]}</b> scope</>;
    };

    private renderPopUpContent = () => {
        const permissionGroups = this.state.data.map(groupData => {
            const permissionGroup = ModuleFE_PermissionGroup.cache.unique(groupData._id);
            if (!permissionGroup)
                throw new MUSTNeverHappenException(`Could not get permission group for id ${groupData._id}`);
            return permissionGroup;
        });

        const allDomainIds = filterDuplicates(flatArray(permissionGroups.map(group => _keys(group._levelsMap!)))) as string[];


        const headers = [userTypeCol, ...allDomainIds];
        return <TS_Table
            rows={permissionGroups}
            header={headers}
            cellRenderer={(col, row) => {
                if (col === userTypeCol)
                    return <div className={'header'}>{row.label}</div>;

                const accessLevel = ModuleFE_PermissionAccessLevel.cache.find(accessLevel => {
                    return accessLevel.domainId === col && row.accessLevelIds.includes(accessLevel._id);
                });

                const className = _className(accessLevel && accessLevel.name.toLowerCase());
                return <div className={className}>{accessLevel?.name ?? 'N/A'}</div>;
            }}
            headerRenderer={header => {
                if (header === userTypeCol)
                    return header;

                const domain = ModuleFE_PermissionDomain.cache.unique(header);
                return domain?.namespace;
            }}
        />;
    };

    render() {
        return <div
            className={'user-permissions__info'}
            {...openContent.tooltip.top('user-types-tooltip', this.renderToolTipContent, {offset: -5})}
            onClick={this.openPopUp}
        >?</div>;
    }
}