import * as React from 'react';
import {AppToolsScreen, LL_V_L, TS_AppTools, TS_ReadMore} from '@nu-art/thunderstorm/frontend';

const text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec lacinia lacus. Mauris condimentum diam felis, eget elementum felis malesuada a. Sed posuere, arcu laoreet ornare efficitur, massa enim ultrices nulla, vel auctor velit odio et leo. Integer mollis, neque sit amet accumsan consectetur, nisl ante finibus tellus, id ultrices magna lorem tincidunt ligula. Cras massa quam, hendrerit maximus risus a, sollicitudin malesuada arcu. Duis pretium semper dignissim. Nam accumsan magna a risus eleifend volutpat in quis nulla. Vivamus hendrerit ex metus, ut elementum mauris interdum luctus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris vel tincidunt sem, sit amet venenatis nunc. Fusce gravida aliquet lacus eu consequat. Mauris vel nulla id massa dignissim commodo quis eu risus. Donec gravida aliquam augue non dignissim.'
	+ '\n\n'
	+ 'Vivamus elementum vestibulum velit a placerat. Aliquam pellentesque nisi ut nisl maximus hendrerit. Ut ornare viverra elit. Donec quis euismod orci, at ornare ipsum. Duis consectetur sapien ligula, facilisis suscipit lacus pellentesque sed. In eu faucibus nisi, nec ornare nisi. Aliquam felis nibh, vulputate eget ante porttitor, malesuada molestie libero. Quisque vel odio vel nulla vulputate tempor. Aliquam feugiat est et pulvinar tempor. Integer vehicula, justo eu ornare tempor, eros nunc volutpat arcu, quis tempus quam risus et dolor. Duis massa arcu, congue non neque vel, viverra consectetur justo.';

const text2 = 'class PgComponent extends React.Component {\n' +
	'\trender() {\n' +
	'\t\treturn <LL_V_L>\n' +
	'\t\t\t{TS_AppTools.renderPageHeader(\'Read More Test\')}\n' +
	'\t\t\t<div style={{padding: \'0 20px\'}}>\n' +
	'\t\t\t\t<TS_ReadMore\n' +
	'\t\t\t\t\ttext={text}\n' +
	'\t\t\t\t\tcollapsedHeight={120}\n' +
	'\t\t\t\t\tfontSize={14}\n' +
	'\t\t\t\t\tlineHeight={20}\n' +
	'\t\t\t\t/>\n' +
	'\t\t\t</div>\n' +
	'\t\t</LL_V_L>;\n' +
	'\t}\n' +
	'}\n' +
	'\n' +
	'export const PgDev_ReadMore: AppToolsScreen = {name: \'Read More Test\', key: \'read-more-test\', renderer: PgComponent, group: \'Advisor Demo\'};';

class PgComponent extends React.Component {
	render() {
		return <LL_V_L style={{gap: '20px'}}>
			{TS_AppTools.renderPageHeader('Read More Test')}
			<div style={{padding: '0 20px', width: '100%'}}>
				<TS_ReadMore
					text={text}
					collapsedHeight={120}
				/>
			</div>
			<div style={{padding: '0 20px', width: '100%'}}>
				<TS_ReadMore
					text={text2}
					collapsedHeight={120}
				/>
			</div>
		</LL_V_L>;
	}
}

export const PgDev_ReadMore: AppToolsScreen = {name: 'Read More Test', key: 'read-more-test', renderer: PgComponent, group: 'Advisor Demo'};