import * as React from 'react';
import {Adapter, AppToolsScreen, ComponentAsync, LL_H_T, LL_V_L, TS_Button, TS_Tree} from '@nu-art/thunderstorm/frontend';
import './PgDev_FHIR.scss';
import {TS_Object} from '@nu-art/ts-common';
import {Example_ColorfulNodeRenderer} from '../../components/tree';
import {Patients_Sandbox} from './patients';


type Props = {}
type State = { output: TS_Object }

class Pg_Component
	extends ComponentAsync<Props, State> {

	render() {
		const adapter = new Adapter(this.state.output).setTreeNodeRenderer(Example_ColorfulNodeRenderer);

		return <LL_H_T>
			<LL_V_L>
				{Patients_Sandbox.map(patient => <TS_Button style={{background: 'red'}} onClick={async e => {
					// const fhirResponse = await ModuleFE_FHIR.vv1.fetchFhir({
					// 	session: {sessionId: 'test', sessionData: {patient_id: patient.patientFhirId, encounter_id: 'test'}},
					// 	queryTemplate: FhirQuery_LabResults
					// }).executeSync();
					// this.logInfo(fhirResponse);
				}}>{patient.name}</TS_Button>)}
			</LL_V_L>
			<TS_Tree adapter={adapter}/>
		</LL_H_T>;
	}
}

export const PgDev_FHIRConsole: AppToolsScreen = {name: 'FHIR Console', key: 'fhir-console', renderer: Pg_Component};

