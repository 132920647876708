import {NodeRendererProps, TreeNode} from '@nu-art/thunderstorm/frontend';
import React = require('react');


export const ExpandCollapseComponentSVG = (props: TreeNode) => {
	const children = props.adapter.getFilteredChildren(props.item);

	let toDisplay;
	if (children.length === 0)
		toDisplay = '';

	else if (!props.expanded)
		toDisplay = <svg width="15" height="15" viewBox="0 0 15 15" fill="currentColor" style={{color: '#9b59b6'}}>
			<path d="M0 14l6-6-6-6z"/>
		</svg>;
	else
		toDisplay = <svg width="15" height="15" viewBox="0 0 15 15" fill="currentColor" style={{color: '#3498db'}}>
			<path d="M0 5l6 6 6-6z"/>
		</svg>;

	return <div className={`clickable`} onClick={props.expandToggler} style={{width: '15px', marginRight: 2}}>{toDisplay}</div>;
};

export class Example_ColorfulNodeRenderer
	extends React.Component<NodeRendererProps> {

	constructor(props: NodeRendererProps) {
		super(props);
	}

	render() {
		const valueStyle = (_value: any) => {
			switch (typeof _value) {
				case 'string':
					return {color: '#e67e22'};

				case 'boolean':
					return {color: '#bf95d0'};

				case 'number':
					if (isNaN(_value))
						return {color: '#e0e0e0'};

					return {color: '#2ecc71'};

				case 'undefined' :
					return {color: '#000'};

				case 'object':
					if (_value === null)
						return {color: '#f1c40f'};

				// eslint-disable-next-line no-fallthrough
				default:
					return {color: '#000000'};
			}
		};

		let value: any;
		const item = this.props.item;
		if (typeof item !== 'object' || item === undefined || item === null)
			value = `${item}`;
		else if (Array.isArray(item))
			value = '[]';
		else if (Object.keys(item).length === 0)
			value = '{}';
		else
			value = '';

		const nameStyle = {color: '#000000'};

		return (
			<div className="ll_h_c">
				<ExpandCollapseComponentSVG {...this.props.node}/>
				<div>
					<span style={nameStyle}>
						{this.props.node.propKey}
					</span>{value !== '' ? ': ' : ''}
					<span style={valueStyle(value)}>{`${value}`}</span>
				</div>
			</div>
		);
	}
}
