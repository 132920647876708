import * as React from 'react';
import {ComponentSync, EditableDBItemV3, LL_V_L, TS_Button} from '@nu-art/thunderstorm/frontend';
import {DB_Resource, DBProto_Resource} from '@app/hcs';
import {ModuleFE_Resource, QPageMessage} from '@app/hcs/frontend';
import {ResourceItem_EditorRenderer} from '@km/managers/ResourceEditor/editors/Editor-renderers/ResourceItem_EditorRenderer';
import {Component_ManagerSubHeader} from '@app/hcs/frontend/ui/shared-components/Component_ManagerSubHeader/Component_ManagerSubHeader';
import {OnSearchResultSelected} from '@app/styles/frontend';
import {BadImplementationException} from '@nu-art/ts-common';


type Props = {
	item?: EditableDBItemV3<DBProto_Resource>
};

type State = {
	item?: EditableDBItemV3<DBProto_Resource>
	isInEditMode?: boolean
};

export class EditorWrapper_Resources
	extends ComponentSync<Props, State>
	implements OnSearchResultSelected {

	__onSearchResultSelected = (selectedResultId: string, entityType: string) => {
		if (entityType !== ModuleFE_Resource.dbDef.dbKey)
			return;

		if (selectedResultId === this.state.item?.item._id)
			return;

		const selectedResult = ModuleFE_Resource.cache.unique(selectedResultId);
		if (!selectedResult)
			throw new BadImplementationException(`Could not find resource for id ${selectedResultId}`);

		this.reDeriveState({item: this.createNewEditable(selectedResult)});
	};

	protected deriveStateFromProps(nextProps: Props, state: State) {
		state.item = nextProps.item ? this.createNewEditable(nextProps.item.item) : state.item;

		state.isInEditMode = state.item && !state.item.item._id;

		return state;
	}

	private createNewEditable(item: Partial<DB_Resource>) {
		return new EditableDBItemV3<DBProto_Resource>(item, ModuleFE_Resource, undefined)
			.setOnChanged(async item => {
				try {
					await item.save();
				} catch (err: any) {
					this.logError(err);
				}
			});
	}

	render() {
		if (!this.state.item)
			return <QPageMessage message={'Resource Manager'}/>;

		return this.state.item && <LL_V_L className={'match_parent'}>
			<Component_ManagerSubHeader isInEditMode={this.state.isInEditMode}
																	buttons={[<TS_Button key={'0'}
																											 className={`${this.state.isInEditMode ? 'selected' : ''}`}
																											 onClick={() => this.setState({isInEditMode: !this.state.isInEditMode})}>
																		{this.state.isInEditMode ? 'VIEW MODE' : 'EDIT MODE'}
																	</TS_Button>,
																		<TS_Button key={'1'}
																							 className={'selected'}
																							 onClick={async () => {
																								 //todo delete
																								 const _item = this.state.item?.item;
																								 if (_item?._id)
																									 await ModuleFE_Resource.v1.delete(_item as DB_Resource).executeSync();
																							 }}>
																			{'Delete Resource'}
																		</TS_Button>]}/>
			<ResourceItem_EditorRenderer editable={this.state.item as EditableDBItemV3<DBProto_Resource>}
																	 isInEditMode={this.state.isInEditMode}/>
		</LL_V_L>;
	}


}