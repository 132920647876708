import {PgDev_SpellCheckTest} from './examples/PgDev_TrieTest/PgDev_SpellCheckTest';
import {PgDev_Dialog} from './examples/PgDev_Dialog/PgDev_Dialog';
import {PgDev_GraphviZ} from './examples/PgDev_Graphviz/PgDev_GraphviZ';
import {PgDev_TS_Slider} from './examples/PgDev_TS_Slider/PgDev_TS_Slider';
import {PgDev_ErrorBoundary} from './examples/PgDev_ErrorBoundary';
import {PgDev_CssTransitions} from './examples/PgDev_CssTransitions';
import {PgDev_Notifications} from './examples/PgDev_Notifications';
import {PgDev_GeneralTesting} from './examples/PgDev_GeneralTesting';
import {PgDev_FHIRConsole} from './examples/PgDev_FHIR/PgDev_FHIR';
import {PgDev_ThemeTest} from './examples/PgDev_ThemeTest/ThemeTest';
import {PgDev_DragAndDrop} from './examples/PgDev_DragAndDrop/PgDev_DragAndDropTest';
import {PgDev_Palette} from './examples/PgDev_Palette/PgDev_Palette';
import {PgDev_DialogIndexing} from './examples/PgDev_DialogIndexing';
import {PgDev_DraggableTable} from './examples/PgDev_DraggableTable/PgDev_DraggableTable';
import {PgDev_DropDownTest} from './examples/PgDev_DropDownTest/PgDev_DropDownTest';
import {PgDev_InputsTest} from './examples/PgDev_InputsTest';
import {PgDev_TS_Table} from './examples/PgDev_TS_Table/PgDev_TS_Table';
import {Playground_Tree_Data} from './examples/Example_Tree_Data';
import {PgDev_VirtualizedElements} from './examples/PgDev_VirtualizedElements/PgDev_VirtualizedElements';
import {PgDev_PopUpTest} from './examples/PgDev_PopUpTests/PgDev_PopUpTest';
import {PgDev_ProgressBar} from './examples/PgDev_ProgressBar/PgDev_ProgressBar';
import {PgDev_WindowMessenger, PgDev_WindowMessengerFrame} from './examples/PgDev_WindowMessenger/PgDev_WindowMessenger';
import {AppToolsScreen} from '@nu-art/thunderstorm/frontend';
import {PgDev_ReadMore} from './examples/PgDev_ReadMore';
import {PgDev_PrintableDP} from './examples/PgDev_PrintableDP';
import {PgDev_DPManager} from './examples/PgDev_DPManager';
import {ATS_FHIRQueryResources} from '@app/advisor-content/frontend/ats/ATS_FHIRQueryResources/ATS_FHIRQueryResources';
import {ATS_NLPModelEditor} from '@app/advisor-content/_entity/nlp-model/frontend/ATS_NLPModelEditor/ATS_NLPModelEditor';

export const playgroundScreens: AppToolsScreen[] = [
	ATS_FHIRQueryResources.Screen,
	ATS_NLPModelEditor.Screen,
	PgDev_ReadMore,
	PgDev_WindowMessenger,
	PgDev_ProgressBar,
	PgDev_PopUpTest,
	PgDev_SpellCheckTest,
	PgDev_Dialog,
	PgDev_GraphviZ,
	PgDev_TS_Slider,
	PgDev_ErrorBoundary,
	PgDev_CssTransitions,
	PgDev_Notifications,
	PgDev_GeneralTesting,
	PgDev_FHIRConsole,
	PgDev_ThemeTest,
	PgDev_DragAndDrop,
	PgDev_Palette,
	PgDev_DialogIndexing,
	PgDev_DraggableTable,
	PgDev_DropDownTest,
	PgDev_InputsTest,
	PgDev_TS_Table,
	Playground_Tree_Data,
	PgDev_VirtualizedElements,
	PgDev_WindowMessengerFrame,
	PgDev_PrintableDP,
	PgDev_DPManager,
];