import * as React from 'react';
import {ResourceType, ResourceTypesArray} from '@app/hcs';
import './ResourceItem_EditorRenderer.scss';
import {LL_H_C, LL_V_L, SimpleListAdapter, TS_DropDown, TS_EditableItemComponentProto, TS_PropRenderer, TS_Space} from '@nu-art/thunderstorm/frontend';
import {Icon} from '@km/managers/ResourceEditor/components/Component_IconPicker';
import {ICONS} from '@app/styles';
import {ConfigKeyFE_SourceTagId, DBProto_Resource, HCSDropDowns, ModuleFE_Tag, ModuleFE_Variable} from '@app/hcs/frontend';
import {BadImplementationException, cloneArr, generateUUID, UniqueId} from '@nu-art/ts-common';
import {StaticProps_TS_MultiSelect_V2} from '@nu-art/thunderstorm/frontend/components/TS_MultiSelect';
import {HCS_MultiSelect} from '@app/hcs/frontend/ui/shared-components/app-multis-select';
import {DBItemDropDownMultiSelector} from '@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector';
import {DefaultEditor_InputText} from '@app/styles/frontend/ui-manager/ui-components';

type Props = { isInEditMode?: boolean }

export class ResourceItem_EditorRenderer
	extends TS_EditableItemComponentProto<DBProto_Resource, Props> {

	readonly Icons: Icon[] = [
		{key: 'Laboratory', icon: <ICONS.newAdvisorLab.component/>},
		{key: 'Imaging', icon: <ICONS.newAdvisorRadiation.component/>},
		{key: 'Physician', icon: <ICONS.newAdvisorPhysician.component/>},
		{key: 'Vital Signs', icon: <ICONS.newAdvisorVitals.component/>},
	];

	readonly multiselectPropsTags: StaticProps_TS_MultiSelect_V2<string> = {
		// label: 'Select Tags Properties',
		itemRenderer: (tagId) => <><ICONS.x.component onClick={async () => {
			if (tagId)
				await this.removeVariableId(tagId, 'tagsProperties');
		}} className={'ts-icon__small'}/>{ModuleFE_Tag.cache.unique(tagId)!.label}</>,
		selectionRenderer: DBItemDropDownMultiSelector.selector(HCSDropDowns.Tag)
	};

	private async removeVariableId(varId: UniqueId, prop: 'variableProperties' | 'tagsProperties') {
		const propertiesId = cloneArr(this.props.editable.item[prop]!);
		propertiesId.splice(propertiesId.indexOf(varId), 1);

		await this.props.editable.updateObj({[prop]: propertiesId});
		this.forceUpdate();
	}

	render() {
		const typeAdapter = SimpleListAdapter(ResourceTypesArray, item => <div>{item.item}</div>);
		const sourceTagId = ConfigKeyFE_SourceTagId.get();
		if (!sourceTagId || sourceTagId.length === 0)
			throw new BadImplementationException('Can\'t find source tag id from AppConfig collection!');

		return (
			<div className={'match_parent resource-editor'}>
				<LL_V_L className={'container'}>
					<div className={'form-title'}>Resource Info</div>
					<TS_Space height={5}/>
					<TS_PropRenderer.Vertical label={'Resource Name'}>
						<DefaultEditor_InputText
							editable={this.props.editable}
							prop={'label'}
							disabled={!this.props.isInEditMode}
						/>
					</TS_PropRenderer.Vertical>
					<TS_Space height={5}/>
					<div className={'ts-prop-renderer__label'}>Type</div>
					<TS_Space height={5}/>
					<TS_DropDown<ResourceType>
						adapter={typeAdapter}
						onSelected={async (selection) => await this.props.editable.updateObj({type: selection})}
						disabled={!this.props.isInEditMode}
						selected={this.props.editable.item.type}/>
					<TS_Space height={5}/>
					<div className={'ts-prop-renderer__label'}>Tag</div>
					<TS_Space height={5}/>
					<HCSDropDowns.Tag
						queryFilter={tag => tag.tagIds.length === 1 && tag.tagIds.includes(sourceTagId)}
						onSelected={async (selection) => await this.props.editable.updateObj({tagId: selection._id})}
						disabled={!this.props.isInEditMode}
						selected={this.props.editable.item.tagId}/>
					<TS_Space height={5}/>
					{this.renderVarEditor()}

					<TS_Space height={5}/>
					{this.renderTagsEditor()}
				</LL_V_L>
			</div>
		);
	}

	private renderTagsEditor() {
		const editable = this.props.editable;
		if (!this.props.isInEditMode)
			return <LL_V_L>
				{editable.item.tagsProperties?.length ?
					<div className={'checkbox-titles'}>Tags Properties</div> : null}
				<LL_H_C className={'match_width variable-list'}>
					{editable.item.tagsProperties?.map(tagId => {
						const tag = ModuleFE_Tag.cache.unique(tagId);
						if (!tag)
							return '';

						return (
							<LL_H_C key={generateUUID()} className={'tag'}>{tag.label}</LL_H_C>
						);
					})}</LL_H_C>
			</LL_V_L>;

		return <HCS_MultiSelect.Tag
			editable={editable}
			prop={'tagsProperties'}/>;
	}

	private renderVarEditor() {
		const editable = this.props.editable;
		if (!this.props.isInEditMode)
			return <LL_V_L>
				{editable.item.variableProperties?.length ?
					<div className={'checkbox-titles'}>Variables Properties</div> : null}
				<LL_H_C className={'match_width variable-list'}>
					{editable.item.variableProperties?.map(varId => {
						const variable = ModuleFE_Variable.cache.unique(varId);
						if (!variable)
							return '';

						return (
							<LL_H_C key={generateUUID()} className={'tag'}>{variable.name}</LL_H_C>
						);
					})}</LL_H_C>
			</LL_V_L>;

		return <HCS_MultiSelect.Variable
			editable={editable}
			prop={'variableProperties'}/>;
	}
}