import * as React from 'react';
import {ComponentSync, ModuleFE_BaseDB} from '@nu-art/thunderstorm/frontend';
import {OnDeleteConflicts, OnTriggerPanel} from '@app/hcs/frontend';
import {Conflict, EntityRef} from '@nu-art/firebase';
import Dialog_ResolveDependenciesConflicts from '../Dialog_ResolveDependenciesConflicts/Dialog_ResolveDependenciesConflics';
import {CollectionItemRenderers, ProtoTypedMap} from '@km/DependenciesConflict/ConflictItemRenderers';

export type ConflictData = { //Identical to DB_EntityDependencyV2 as of now
	originalItemsToDelete: EntityRef[] // trying to delete variables a, b and c
	issues: Conflict[] // an array of variables a, b and c and each with their conflicts, or empty conflicts array for items that don't have conflicts between items that do
}

type Props = {}

type State = {
	display: boolean, //show overlay
	conflictData: ConflictData[]
}

export class ConflictResolver_Overlay
	extends ComponentSync<Props, State>
	implements OnDeleteConflicts, OnTriggerPanel {

	private CollectionRenderers: ProtoTypedMap = CollectionItemRenderers;


	protected deriveStateFromProps(nextProps: Props, state: State) {
		state.display ??= false;
		state.conflictData ??= [];

		//todo reduce issues to a new type, of {target:EntityRef, issues:EntityRefs[]} ???
		return state;
	}

	__onDeleteConflicts(entityDependencies: ConflictData[]): void {
		//todo subscribe to all module updates to be able to update the overlay live
		// const relevantDbModules = RuntimeModules().filter((module: ModuleFE_BaseApi<any>) => {
		// 	//Get all relevant module dbKeys
		// 	const relevantModuleDbKeys = filterDuplicates([
		// 		...entityDependencies.originalItemsToDelete.map(target => target.dbKey),
		// 		...entityDependencies.issues.map(conflict => conflict.conflicts.dbKey)
		// 	]);
		//
		// 	return relevantModuleDbKeys.includes(module.dbDef.dbKey);
		// });

		// relevantDbModules.map(module => {
		// 	// @ts-ignore
		// 	const __callback = this[module.defaultDispatcher.method]?.bind(this);
		// 	// @ts-ignore
		// 	this[module.defaultDispatcher.method] = (...params: ApiCallerEventType<any>) => {
		// 		// @ts-ignore
		// 		this.updateList(module, ...params);
		// 		__callback?.(...params);
		// 	};
		// });

		this.reDeriveState({
			display: true, conflictData: entityDependencies
		});
	}

	listenToModules = (modules: ModuleFE_BaseDB<any>[]) => {
		//listen
		modules.forEach(module => {
			const method = module.defaultDispatcher.method;
			// @ts-ignore
			this[method] = () => {
				//todo insert dbKey into called method
				// on${dbEntity}Updated(...)
			};
		});
	};
	stopListeningToModules = (modules: ModuleFE_BaseDB<any>[]) => {
		//remove
		modules.forEach(module => {
			const method = module.defaultDispatcher.method;
			// @ts-ignore
			delete this[method];
		});
	};

	// private updateList(module: ModuleFE_v3_BaseApi<any>, ...params: ApiCallerEventTypeV3<any>) {
	// 	// if (params[0] !== 'delete-multi' && params[0] !== 'delete')
	// 	// 	return;
	//
	// 	//todo Update the conflicts list if relevant objects update
	// }

	__onTriggerPanel() {
		this.reDeriveState({display: !this.state.display});
	}

	render() {
		if (!this.state.display)
			return '';

		return (
			<Dialog_ResolveDependenciesConflicts
				conflictData={this.state.conflictData}
				collectionRenderers={this.CollectionRenderers}
			/>
		);
	}
}