import * as React from 'react';
import {ComponentSync, EditableDBItemV3, LL_V_L, TS_BusyButton, TS_PropRenderer} from '@nu-art/thunderstorm/frontend';
import {DB_Account} from '@nu-art/user-account';
import {DefaultEditor_InputText_Optional} from '@app/styles/frontend/ui-manager/ui-components';
import './Component_UserDetailsEditor.scss';
import {ModuleFE_User} from '@app/org/_entity/user/frontend';
import {DBProto_User} from '@app/org/_entity/user/shared';

type Props = {
    account: DB_Account;
    onSubmitCompleted?: VoidFunction;
}

type State = {
    user?: EditableDBItemV3<DBProto_User>;
}

export class Component_UserDetailsEditor
    extends ComponentSync<Props, State> {

    // ######################### Lifecycle #########################

    protected deriveStateFromProps(nextProps: Props, state: State): State {
        if (!state.user) {
            const user = ModuleFE_User.cache.unique(nextProps.account._id);
            if (!user)
                return state;

            state.user = new EditableDBItemV3(user, ModuleFE_User);
        }
        return state;
    }

    // ######################### Logic #########################

    private submitUserChanges = async () => {
        try {
            await this.state.user?.save();
            this.props.onSubmitCompleted?.();
        } catch (err: any) {
            this.logError(err.message, err);
            this.forceUpdate();
        }
    };

    // ######################### Render #########################

    render() {
        const user = this.state.user;
        if (!user)
            return this.renderNoUser();

        return <LL_V_L className={'edit-user-details'}>
            <TS_PropRenderer.Vertical label={'First Name'} error={user.hasError('firstName')}>
                <DefaultEditor_InputText_Optional
                    editable={user}
                    prop={'firstName'}
                />
            </TS_PropRenderer.Vertical>
            <TS_PropRenderer.Vertical label={'Surname'} error={user.hasError('surname')}>
                <DefaultEditor_InputText_Optional
                    editable={user}
                    prop={'surname'}
                />
            </TS_PropRenderer.Vertical>
            <TS_PropRenderer.Vertical label={'Title'} error={user.hasError('title')}>
                <DefaultEditor_InputText_Optional
                    editable={user}
                    prop={'title'}
                />
            </TS_PropRenderer.Vertical>
            <TS_BusyButton onClick={this.submitUserChanges}>Submit</TS_BusyButton>
        </LL_V_L>;
    }

    private renderNoUser = () => {
        return <div className={'edit-user-details__no-user'}>Could not find user</div>;
    };
}