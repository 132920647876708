import * as React from 'react';
import {ModuleFE_Workspace, TS_HorizontalWorkspace, TS_Workspace} from '@nu-art/ts-workspace/frontend';
import {ComponentSync, ModuleFE_BaseDB, TS_ErrorBoundary, TS_Route} from '@nu-art/thunderstorm/frontend';
import './Panel_ResourceManager.scss';
import {PanelWrapper_ResourceManager} from '@km/managers/ResourceEditor/old/PanelWrapper_ResourceManager';
import {ModulePackFE_Organization} from '@app/org/frontend';
import {ModuleFE_Resource} from '@app/hcs/frontend';
import {EditorWrapper_Resources} from '@km/managers/ResourceEditor/editors/EditorWrapper_Resources';

export class Panel_ResourceManager_V1_old
	extends ComponentSync {

	static Route: TS_Route = {
		key: 'resource-manager',
		path: 'resource-manager',
		Component: this,
		enabled: ()=> false,
		modulesToAwait: [...ModulePackFE_Organization, ModuleFE_Resource] as ModuleFE_BaseDB<any>[]
	};

	readonly key: string = 'search';

	readonly renderers: { [key: string]: React.ElementType } = {
		horizontalSpace: TS_HorizontalWorkspace,
		searchPanel: PanelWrapper_ResourceManager,
		dataViewer: EditorWrapper_Resources
	};

	render() {
		return (
			<TS_ErrorBoundary onClick={async (e: any) => {
				await ModuleFE_Workspace.deleteWorkspaceByKey(this.key);
				this.forceUpdate();
			}}>
				<TS_Workspace workspaceKey={this.key} renderers={this.renderers}/>
			</TS_ErrorBoundary>
		);
	}

}