import * as React from 'react';
import {Outlet} from 'react-router-dom';
import {ComponentSync, LL_H_T, LL_V_C, ModuleFE_AppConfig, TS_Route} from '@nu-art/thunderstorm/frontend';
import './Page_KnowledgeManager.scss';
import {Component_Header, Component_SideNavBar} from '@km/components';
import {RouteDomainsMap} from '@modules/app-module/ModuleFE_AppModule';
import {_values} from '@nu-art/ts-common';


export class KnowledgeManagerOverlay
	extends ComponentSync {

	static Route: TS_Route = {
		key: 'km',
		path: 'km',
		Component: this,
		index: true,
		modulesToAwait: [ModuleFE_AppConfig],
		children: [
			..._values(RouteDomainsMap).map(item => item.route),
		],
	};

	render() {
		return <LL_V_C className={'knowledge-manager'}>
			<Component_Header/>
			<LL_H_T className={'knowledge-manager__main'}>
				<Component_SideNavBar/>
				<div className={'knowledge-manager__main__content'}>
					<Outlet/>
				</div>
			</LL_H_T>
		</LL_V_C>;
	}
}