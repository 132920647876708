import * as React from 'react';
import {ComponentSync, DBProto_AppConfig, DispatcherType_AppConfig, ModuleFE_RoutingV2, TS_Loader, TS_Route} from '@nu-art/thunderstorm/frontend';
import {LoggedStatus, ModuleFE_Account, OnLoginStatusUpdated} from '@nu-art/user-account/frontend';
import {Page_Login} from '../Page_Login';
import {BadImplementationException} from '@nu-art/ts-common';
import {ApiCallerEventType, DispatcherInterface} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';


export class LoginComponent
	extends ComponentSync<{ rootRoute: TS_Route }>
	implements OnLoginStatusUpdated, DispatcherInterface<DispatcherType_AppConfig> {

	__onAppConfigsUpdated = (...params: ApiCallerEventType<DBProto_AppConfig>) => {
		this.forceUpdate();
	};

	__onLoginStatusUpdated(): void {
		this.forceUpdate();
	}

	protected deriveStateFromProps(nextProps: { rootRoute: TS_Route }): any {
	}

	render() {
		const status = ModuleFE_Account.getLoggedStatus();
		switch (status) {
			case LoggedStatus.SESSION_TIMEOUT:
			case LoggedStatus.LOGGED_OUT:
				return <Page_Login/>;

			case LoggedStatus.VALIDATING:
				return <div className={'loader-container'}><TS_Loader/></div>;

			case LoggedStatus.LOGGED_IN: {
				// return <PermissionRoutes rootRoute={this.props.rootRoute}/>;
				return ModuleFE_RoutingV2.generateRoutes(this.props.rootRoute);
			}

			default:
				throw new BadImplementationException(`logged status can only be one of 'LOGGED_IN', 'LOGGED_OUT' or 'VALIDATING'`);
		}
	}
}