import {QKeyBinder} from '@app/hcs/frontend';
import {ComponentSync, ModuleFE_Dialog} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';

type Props = {};

type State = {
	buttonRefs: {
		ref: React.RefObject<HTMLDivElement>;
		key: string;
	}[];
	shiftKeyDown: boolean
};

const arr = [0, 1, 2, 3];

export class TestDialog
	extends ComponentSync<Props, State> {

	protected deriveStateFromProps(nextProps: Props): State {
		const refs = arr.map((i) => {
			return {ref: React.createRef<HTMLDivElement>(), key: `button-${i}`};
		});
		return {buttonRefs: refs, shiftKeyDown: false};
	}

	render() {
		return (
			<div className={'test-dialog'}>
				<div className={'test-dialog__header'}>
					<span>Test Dialog</span>
					<div className={'exit-button'} onClick={() => ModuleFE_Dialog.close()}>Close Dialog</div>
				</div>
				<QKeyBinder className={'test-dialog__content'} id={'test-dialog'}>
					{arr.map((i) => {
						const key = this.state.buttonRefs[i].key;
						return <div
							key={key}
							className={'indexed-button'}
							tabIndex={0}
						>{`button ${i}`}</div>;
					})}
				</QKeyBinder>
			</div>
		);
	}
}