import {ComponentSync, LL_H_C, LL_V_L, ModuleFE_AppConfig, ModuleFE_BaseApi, Show, TS_CollapsableContainer, TS_PropRenderer} from '@nu-art/thunderstorm/frontend';
import './Dialog_ResolveDependenciesConflict.scss';
import * as React from 'react';
import {ICONS} from '@app/styles';
import {_values, DBProto, RuntimeModules, TypedMap, UniqueId} from '@nu-art/ts-common';
import {dispatch_onTriggerPanel, ModuleFE_Tag, ModuleFE_Variable} from '@app/hcs/frontend';
import {EntityRef, EntityRefs} from '@nu-art/firebase';
import {ModuleFE_DiseaseProfile} from '@app/dp/_entity/disease-profile/frontend';
import {ModuleFE_MultiVar} from '@app/dp/_entity/multi-var/frontend';
import {ConflictData} from '../ConflictResolver_Overlay/ConflictResolver_Overlay';
import {DB_MultiVar} from '@app/dp/_entity/multi-var/shared';
import {ProtoTypedMap} from '@km/DependenciesConflict/ConflictItemRenderers';

type Props = {
	conflictData: ConflictData[];
	collectionRenderers: ProtoTypedMap
}
type SummarizedConflict = { target: EntityRef, issues: EntityRefs[] };

type State = {
	conflicts: SummarizedConflict[];
	diseaseViewTagGroupId: UniqueId;
}

/**
 * This entire component is garbage and in desperate need of refactor...
 * Address asap
 */
export default class Dialog_ResolveDependenciesConflicts
	extends ComponentSync<Props, State> {

	protected deriveStateFromProps(nextProps: Props, state: State) {
		const mappedConflicts = nextProps.conflictData.reduce<TypedMap<{ target: EntityRef, mappedDbKeyToIssues: TypedMap<EntityRefs> }>>((sum, current) => {
			current.issues.forEach(conflict => {
				//If somehow received empty list of issues, ignore
				if (conflict.conflicts.ids.length === 0)
					return sum;

				const targetStringIdentifier = `${conflict.target.dbKey}${conflict.target.id}`;

				const currentConflict = sum[targetStringIdentifier] ?? {};

				//First TypedMap is target hashed dbKey/id, to target and map of issues by dbKey.
				//The target is always the same target.
				currentConflict.target = conflict.target;
				//Initialize if doesn't exist...
				if (!currentConflict.mappedDbKeyToIssues)
					currentConflict.mappedDbKeyToIssues = {};

				//2nd TypedMap is dbKey to EntityRefs
				currentConflict.mappedDbKeyToIssues[conflict.conflicts.dbKey] = conflict.conflicts;
				sum[targetStringIdentifier] = currentConflict;
			});
			return sum;
		}, {});

		//Spread the conflicts best for rendering, array of target to array of issues by dbKey to object ids
		state.conflicts = _values(mappedConflicts).map(conflictItem => ({
			target: conflictItem.target,
			issues: _values(conflictItem.mappedDbKeyToIssues)
		}));

		//todo Why do I need DV tag group id???
		state.diseaseViewTagGroupId = ModuleFE_AppConfig.cache.find(config => config.key === 'diseaseViewTagId')!.data;

		return state;
	}

	render() {
		return <LL_V_L id={'resolve-dependency-conflicts-panel'} className={'dialog'}>
			{this.renderHeader()}
			{this.state.conflicts.length ?
				<LL_V_L className={'target-container'}>{this.state.conflicts.map(conflictSum => {
					const targetDbKey = conflictSum.target.dbKey;
					return <div key={`${conflictSum.target.dbKey}${conflictSum.target.id}`}
								className={'target-to-delete'}>
						{this.renderTargetToDelete(targetDbKey, conflictSum.target.id)}
						{conflictSum.issues.map(issue => {
							const issueModule = this.getModuleByDbKey(issue.dbKey);
							if (!issueModule)
								return `Failed to find dbModule for dbKey ${issue.dbKey}`;

							return <TS_CollapsableContainer
								key={`${conflictSum.target.dbKey}${conflictSum.target.id}${issue.dbKey}`}
								className={'collapsable'}
								showCaret={true}
								customCaret={<ICONS.advisor_v4_arrow.component/>}
								headerRenderer={this.renderCollectionName(issue.dbKey)}
								containerRenderer={this.renderCollectionIssuesByIds(issueModule, issue.ids)}
							/>;
						})}
					</div>;
				})}</LL_V_L> :
				<LL_V_L className={'match_width'}>Nothing to show here, good job!</LL_V_L>}
		</LL_V_L>;
	}

	private renderTargetToDelete = (targetDbKey: string, targetId: string) => {
		const targetDbModule = this.getModuleByDbKey(targetDbKey);
		if (!targetDbModule)
			return `Failed to find dbModule for dbKey ${targetDbKey}`;
		return <TS_PropRenderer.Vertical label={'Deletion Target'}>
			<LL_H_C className={'target_header'}>
				<TS_PropRenderer.Vertical label={'Collection'}>
					<div className={'target-collection-name'}>{targetDbKey}</div>
				</TS_PropRenderer.Vertical>
				{this.renderCollectionItem(targetDbKey, targetDbModule.cache.unique(targetId))}
			</LL_H_C>
		</TS_PropRenderer.Vertical>;
	};

	private getModuleByDbKey(dbKey: any) {
		return RuntimeModules()
			.filter<ModuleFE_BaseApi<any>>(module => !!module.getCollectionName)
			.find(module => module.dbDef.dbKey === dbKey);
	}

	private getRendererForDbItem<T extends DBProto<any>>(dbKey: T['dbKey'], dbItem: T['dbType']): JSX.Element {
		let ToRender = this.props.collectionRenderers[dbKey]?.(dbItem); // if exists in the map, take it from there
		if (ToRender)
			return ToRender;

		ToRender = <React.Fragment key={dbItem._id}/>;
		switch (dbKey) {
			case ModuleFE_MultiVar.dbDef.dbKey:
				ToRender = <React.Fragment key={dbItem._id}>{this.getMultiVarTextualView(dbItem)}</React.Fragment>;
				break;
		}
		return ToRender;
	}

	private renderCollectionItem<T extends DBProto<any>>(dbKey: T['dbKey'], dbItem: T['dbType']) {
		return this.getRendererForDbItem(dbKey, dbItem);
	}

	private renderCollectionName(dbKey: string) {
		//todo translate dbKeys to presentable names
		return <div className={'ts-collapsable-container__header__text'}>{dbKey}</div>;
	}

	private renderCollectionIssuesByIds(collectionModule: ModuleFE_BaseApi<any>, ids: UniqueId[]) {
		const dbItems = ids.map(id => collectionModule.cache.unique(id));
		return dbItems.map(item => {
			return this.renderCollectionItem(collectionModule.dbDef.dbKey, item);
		});
	}

	private renderHeader() {
		return <LL_H_C className="dialog__header header-position">
            <span className="header-title">{this.state.conflicts.length ? 'Deletion Conflicts'
				: 'All clean and tidy'}</span>
			<ICONS.x.component
				className={'exit_icon'}
				onClick={(e) => {
					e.stopPropagation();
					dispatch_onTriggerPanel.dispatchUI();
				}}
			/>
		</LL_H_C>;
	}

	private getMultiVarTextualView(multiVar: DB_MultiVar) {
		if (!multiVar)
			return 'Not Found';

		const diseaseProfile = ModuleFE_DiseaseProfile.cache.unique(multiVar.diseaseProfileId);
		if (!diseaseProfile) //A MultiVar is meaningless without a Disease Profile
			return 'Correlated Disease Profile Not Found';

		const variable = ModuleFE_Variable.cache.unique(multiVar._firstVarId);
		if (!variable) //A MultiVar is meaningless without a Variable
			return 'Correlated Variable Not Found';

		const tags = multiVar.variables[0].tagIds;
		const tagGroupIndex = tags.findIndex(_tg => _tg.key === this.state.diseaseViewTagGroupId);
		let diseaseView;
		if (tagGroupIndex !== -1)
			diseaseView = ModuleFE_Tag.cache.unique(tags[tagGroupIndex].value[0]);

		return <LL_H_C className={'multivar-textual-row'}>
			<Show>
				<Show.If condition={true}>
					<TS_PropRenderer.Vertical label={'Disease Profile'}>
						{`${diseaseProfile.label}${diseaseView ? ', ' : ''}`}
					</TS_PropRenderer.Vertical>
					{/*{diseaseView ? <div style={{width: '4px'}}/> : <></>}*/}
				</Show.If>
				<Show.If condition={!!diseaseView}>
					<TS_PropRenderer.Vertical label={'Disease View'}>
						{`${diseaseView?.label}, `}
					</TS_PropRenderer.Vertical>
				</Show.If>
				<Show.If condition={true}>
					<TS_PropRenderer.Vertical label={'Variable'}>
						{variable.name}
					</TS_PropRenderer.Vertical>
				</Show.If>
			</Show>
		</LL_H_C>;
	}
}