import {PanelConfig} from '@nu-art/ts-workspace/frontend';

export const WorkspaceConfig_DiseaseProfile_VarList: PanelConfig<'horizontalSpace'> = {
	name: 'Disease Property Data',
	key: 'horizontalSpace',
	visible: true,
	factor: 1,
	min: -1,
	data: {
		panels: [
			{
				name: 'leftPanel', key: 'variables', visible: true, factor: 1, min: 200,
				data: {}
			},
		]
	}
};