import * as React from 'react';
import {_className, ComponentSync} from '@nu-art/thunderstorm/frontend';
import {ICONS} from '@app/styles';
import {ModuleFE_AppModule} from '@modules/app-module/ModuleFE_AppModule';
import {DBProto_Discussion, DispatcherType_Discussion, ModuleFE_Discussion} from '@app/hcs/frontend';
import {ApiCallerEventType, DispatcherInterface} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

type Props = {}
type State = {
	unresolvedDiscussions: boolean
}

export class Component_DiscussionButton
	extends ComponentSync<Props, State>
	implements DispatcherInterface<DispatcherType_Discussion> {

	__onDiscussionsUpdated(...params: ApiCallerEventType<DBProto_Discussion>) {
		this.reDeriveState();
	}

	protected deriveStateFromProps(nextProps: Props, state: State) {
		state.unresolvedDiscussions = ModuleFE_Discussion.cache.all().some(discussion => discussion.status !== 'resolved');
		return state;
	}

	private getEnvString() {
		switch (ModuleFE_AppModule.getEnv()?.toLowerCase()) {
			case 'local':
				return 'local';
			case 'dev':
				return 'dev';
			case 'staging':
				return 'staging';
			default:
				return '';
		}
	}

	render() {
		return <div className={'container-discussions-button'}>
			<ICONS.messageBubble.component className={'discussions-button'}
																		 onClick={() => {
																			 this.logInfo('Clicked on DiscussionsView button');
																			 ModuleFE_Discussion.openDiscussions({});
																		 }}/>
			{this.state.unresolvedDiscussions &&
				<div className={_className('there-are-unresolved-discussions', this.getEnvString())}/>}
		</div>;
	}
}