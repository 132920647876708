import * as React from 'react';
import './Component_SearchV2.scss';
import {
	ModulePackFE_HCS_SeparatePermissionDomainModules,
	ModulePackFE_HealthcareSpace,
	PermissionKeyFE_Search_Release,
	SearchableItem_Tags,
	SearchableItem_Values,
	SearchableItem_Variables,
	SearchItem_MacroTag
} from '@app/hcs/frontend';
import {EditableDBItemV3, LL_V_L, ModuleFE_BaseApi, ProtoComponent, TS_ErrorBoundary, TS_Route} from '@nu-art/thunderstorm/frontend';
import {BadImplementationException, MUSTNeverHappenException, UniqueId} from '@nu-art/ts-common';
import {ModuleFE_Workspace, TS_HorizontalWorkspace, TS_Workspace} from '@nu-art/ts-workspace/frontend';
import {RoutePermissions} from '@nu-art/permissions/frontend';
import {Component_SearchAndResults, SearchItemV3} from '@app/styles/frontend';
import {ModulePackFE_DiseaseProfile} from '@app/dp/frontend';
import {SearchableItem_DiseaseProfiles, SearchableItem_DiseaseViews, SearchableItem_MultiVars} from '@app/dp/frontend/ui/search-items';
import {Page_PathwayEditor} from '@app/pathway/frontend';
import {Page_OrganizationEditor} from '@app/org/frontend';
import {ModuleFE_SyncManager} from '@nu-art/thunderstorm/frontend/modules/sync-manager/ModuleFE_SyncManager';
import {Page_ResourceEditor_V2} from '@app/hcs/frontend/ui/Page_ResourceEditor/Page_ResourceEditor_V2';
import {Page_OrderEditor} from '@app/pathway/frontend/ui/Page_OrderEditor/Page_OrderEditor';
import {SearchAndResult_SelectedItem} from '@app/styles/frontend/ui/search-v2/types';
import {Page_ExpressionManager} from '@app/hcs/frontend/ui/expressions/ui/expression-manager/Page_ExpressionManager';
import {ProtoDef_Search} from './proto-def';
import {Manager_Permissions} from '../../managers/Manager_Permissions/Manager_Permissions';
import {WorkspaceKey_Search} from '../../../modules/WorkspaceModuleFE/workspaceConfigs';

export type  SearchConfig = {
	searchItems: SearchItemV3<any>[];
}

type Props = ProtoDef_Search['props'] & {
	config: SearchConfig;
}

type State = ProtoDef_Search['state'] & {
	searchItems: SearchItemV3<any>[];
	selectedItem?: {
		id: UniqueId;
		type: string;
	}
}

const resolveModulesToAwait = () => {
	const allModulesToShowInSearch = [...ModulePackFE_HealthcareSpace, ...ModulePackFE_HCS_SeparatePermissionDomainModules, ...ModulePackFE_DiseaseProfile] as ModuleFE_BaseApi<any>[];
	const modulesYouHavePermissionToSee = ModuleFE_SyncManager.getPermissibleModuleNames()!;
	return allModulesToShowInSearch.filter(module => modulesYouHavePermissionToSee.includes(module.dbDef?.dbKey));
};

export class Component_SearchV2
	extends ProtoComponent<ProtoDef_Search, Props, State> {

	static Route: TS_Route = {
		key: 'search-v2',
		path: 'search-v2',
		Component: this,
		enabled: RoutePermissions(PermissionKeyFE_Search_Release),
		modulesToAwait: resolveModulesToAwait,
		awaitSync: true,
	};

	static defaultProps: Partial<Props> = {
		keys: ['selectedItem'],
		config: {
			searchItems: [
				SearchableItem_Tags,
				SearchableItem_Variables,
				SearchableItem_DiseaseProfiles,
				SearchableItem_MultiVars,
				SearchableItem_Values,
				SearchableItem_DiseaseViews,
				Page_OrganizationEditor.SearchableItem_Organization,
				Page_ResourceEditor_V2.SearchableItem_Resource,
				Page_ExpressionManager.SearchableItem_Expression,
				Page_PathwayEditor.SearchableItem_Pathway,
				Manager_Permissions.SearchableItem_User,
				Page_OrderEditor.SearchableItem_Action,
				SearchItem_MacroTag,
			]
		}
	};

	readonly renderers: {
		[key: string]: React.ElementType
	} = {
		horizontalSpace: TS_HorizontalWorkspace,
		searchAndResults: Component_SearchAndResults,
	};

	protected deriveStateFromProps(nextProps: Props, state: State) {
		const permissibleModuleNames = ModuleFE_SyncManager.getPermissibleModuleNames()!;
		state.searchItems = nextProps.config.searchItems.filter(searchItem => permissibleModuleNames.includes(searchItem.module.dbDef.dbKey));

		//Get selected item from URL
		const selectedItem = this.getQueryParam('selectedItem');

		if (!selectedItem) {
			state.selectedItem = undefined;
		} else {
			const searchableItem = state.searchItems.find(searchItem => searchItem.type === selectedItem.key);
			//Searchable item was not found for this key, clean url
			if (!searchableItem) {
				this.logWarning('Searchable item not with key was not in permissible modules');
				state.selectedItem = undefined;
			} else {
				state.selectedItem = {id: selectedItem.id, type: selectedItem.key};
			}
		}

		return state;
	}

	// ######################### Logic #########################

	private onSearchItemSelected = (item: SearchAndResult_SelectedItem) => {
		if (!item.id)
			return this.deleteQueryParam('selectedItem');

		this.setQueryParam('selectedItem', {
			id: item.id,
			key: item.type,
		});
	};

	// ######################### Render #########################

	private renderSelectedResult = (config: any) => {
		const selectedItem = this.state.selectedItem;
		if (!selectedItem)
			return <></>;

		const searchItem = this.state.searchItems.find(item => item.type === selectedItem.type);
		if (!searchItem) {
			throw new BadImplementationException(`No search item for key ${this.state.selectedItem}`);
		}

		const Renderer = (searchItem as unknown as SearchItemV3<any>).resultRendererV2;
		if (Renderer) {
			//This might cause an issue where changes don't bring re-render
			const dbItem = searchItem.module.cache.unique(selectedItem.id);
			if (!dbItem)
				throw new MUSTNeverHappenException(`Could not get dbItem for id ${selectedItem.id} with module ${searchItem.module.getName()}`);
			const editable = new EditableDBItemV3(dbItem, searchItem.module as unknown as ModuleFE_BaseApi<any>);
			return <Renderer item={editable}/>;
		}

		return searchItem.resultRenderer(selectedItem.id);
	};

	render() {
		return (
			<TS_ErrorBoundary onClick={async (e: any) => {
				await ModuleFE_Workspace.deleteWorkspaceByKey(WorkspaceKey_Search);
				this.forceUpdate();
			}}>
				<LL_V_L id={'search-panel'}>
					<TS_Workspace
						workspaceKey={WorkspaceKey_Search}
						renderers={this.renderers}
						instances={{
							searchAndResults: <Component_SearchAndResults
								searchItems={this.state.searchItems}
								selectedItem={this.state.selectedItem}
								onSearchItemSelected={this.onSearchItemSelected}
							/>,
							selectedResultViewer: this.renderSelectedResult
						}}/>
				</LL_V_L>
			</TS_ErrorBoundary>
		);
	}
}