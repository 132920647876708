import * as React from 'react';
import {AppToolsScreen, ComponentSync, LL_H_C, ModuleFE_ActionProcessor, TS_AppTools, TS_BusyButton} from '@nu-art/thunderstorm/frontend';
import {ActionKey_ParseSnomedCSV} from '@app/hcs';

type State = {};

class DevPage_SnomedDictionaryActions_Renderer
	extends ComponentSync<{}, State> {

	render() {
		return <div className={'collection-upgrades-page'}>
			{TS_AppTools.renderPageHeader('Snomed Dictionary Actions')}
			<LL_H_C className={'buttons-container'}>
				{this.renderParseCSVButton()}
			</LL_H_C>
		</div>;
	}

	renderParseCSVButton = () => {
		return <TS_BusyButton
			key={'parse-csv-button'}
			onClick={async () => {
				await ModuleFE_ActionProcessor.vv1.execute({key: ActionKey_ParseSnomedCSV}).executeSync();
			}}
		>button</TS_BusyButton>;
	};
}

export const ATS_SnomedDictionaryActions: AppToolsScreen = {
	name: 'Snomed Dictionary Actions',
	key: 'snomed-dictionary-actions',
	renderer: DevPage_SnomedDictionaryActions_Renderer,
	group: 'Quai Dev Tools',
};