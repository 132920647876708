import * as React from 'react';
import {AppToolsScreen} from '@nu-art/thunderstorm/frontend';
import {Printable_DiseaseProfile} from '@app/dp/frontend';
import {QScrollWrapper} from '@app/hcs/frontend';

class PgComponent extends React.Component {
	render() {
		return <QScrollWrapper className={'match_parent'}>
			<Printable_DiseaseProfile diseaseProfileId={'74a1df9c7a09374a99925d71e537512f'}/>
		</QScrollWrapper>;
	}
}

export const PgDev_PrintableDP: AppToolsScreen = {name: 'Printable DP', key: 'printable-dp', renderer: PgComponent};