import {Module} from '@nu-art/ts-common';
import {ModuleFE_DefaultPermissions, PermissionKey_FE} from '@nu-art/permissions/frontend/PermissionKey_FE';
import {PermissionManagerPermissionKeys} from '@app/shared';

class ModuleFE_PermissionManagerUI_Class extends Module<any> {
	readonly permissions: ModuleFE_DefaultPermissions<typeof PermissionManagerPermissionKeys, any>;

	constructor() {
		super();

		this.permissions = {
			ui: PermissionKey_FE.generatePermissionKeysByLevels(PermissionManagerPermissionKeys),
			collection: {}
		};
	}
}

export const ModuleFE_PermissionManagerUI = new ModuleFE_PermissionManagerUI_Class();