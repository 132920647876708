import * as React from 'react';
import {LL_V_L} from '@nu-art/thunderstorm/frontend/components/Layouts/Layouts';
import {TS_Button} from '@nu-art/thunderstorm/frontend/components/TS_Button/TS_Button';
import {AppToolsScreen, ComponentSync, TS_TextArea} from '@nu-art/thunderstorm/frontend';
import {arrayTrieGet, trieSuggest} from '@smikhalevski/trie';
import {filterInstances} from '@nu-art/ts-common';
import {ModuleFE_Dictionary} from '@app/hcs/frontend';

type State = {
	input?: string
	suggestions?: string[]
}

class PgComponent
	extends ComponentSync<{}, State> {

	protected deriveStateFromProps(nextProps: {}, state: State) {
		const trieSuggestions = state.input ? trieSuggest(ModuleFE_Dictionary.trie, state.input) : undefined;
		state.suggestions = filterInstances(trieSuggestions?.map(suggest => suggest.key));
		return state;
	}

	private doSomething = async () => {
		if (this.state.input)
			this.logWarning(this.getSpellCheckTypos(this.state.input));
	};

	render() {
		return <LL_V_L>
			<TS_TextArea type={'text'}
									 value={this.state.input}
									 onChange={(value) => this.reDeriveState({input: value})}

			/>
			{this.renderButtons()}
			{this.state.suggestions}
		</LL_V_L>;
	}

	private renderButtons = () => {
		return <TS_Button onClick={this.doSomething}>Do something</TS_Button>;
	};

	private getSpellCheckTypos(origin: string): string[] {
		const foundTypos = origin.split(/\b/).reduce<string[]>((typos, word) => {
			if (arrayTrieGet(ModuleFE_Dictionary.arrayTrie, word)) // found in custom dictionary
				return typos;

			// if (!ModuleFE_Dictionary.spellchecker.check(word)) // didn't find in Hunspell dictionary
			// 	typos.push(word);

			return typos;
		}, []);
		return foundTypos;
	}

	// @ts-ignore
	private getSpellCheckedString(origin: string): string {

		return origin;
	}

}

export const PgDev_SpellCheckTest: AppToolsScreen = {
	name: 'SpellCheck Test',
	key: 'spellcheck-test',
	renderer: PgComponent,
};