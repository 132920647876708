import * as React from 'react';
import {AppToolsScreen, ComponentSync, LL_H_C, LL_V_L} from '@nu-art/thunderstorm/frontend';
import './PgDev_DropDownTest.scss';
import {DropDown_Variable, HCSDropDowns, ModuleFE_Variable} from '@app/hcs/frontend';
import {DB_Variable} from '@app/hcs';
import {voidFunction} from '@nu-art/ts-common';

type Props = {}

type State = {
	selected1?: DB_Variable;
}

class PgDev_DropDownTest_class
	extends ComponentSync<Props, State> {

	render() {
		return <LL_H_C className={'match_parent'} style={{padding: '30px', gap: '30px'}}>
			<LL_V_L className={'dropdown-container'}>
				<div>DropDown.Variable</div>
				<HCSDropDowns.Variable
					canUnselect={true}
					className={'fancy'}
					selected={this.state.selected1}
					onSelected={item => this.setState({selected1: item})}
					boundingParentSelector={'.dropdown-container'}
				/>
			</LL_V_L>
			<LL_V_L className={'dropdown-container'}>
				<div>DropDown_Variable</div>
				<DropDown_Variable
					canUnselect={true}
					onSelected={voidFunction}
					boundingParentSelector={'.dropdown-container'}
				/>
			</LL_V_L>
		</LL_H_C>;
	}
}

export const PgDev_DropDownTest: AppToolsScreen = {name: 'Drop Down Test', key: 'dropdown-tests', renderer: PgDev_DropDownTest_class, modulesToAwait: [ModuleFE_Variable]};