import * as React from 'react';
import {AppToolsScreen, ComponentSync, TS_VirtualizedList} from '@nu-art/thunderstorm/frontend';


class VirtualizedElements
	extends ComponentSync {

	protected deriveStateFromProps(nextProps: any) {
		return;
	}

	private random_bg_color() {
		const x = Math.floor(Math.random() * 256);
		const y = Math.floor(Math.random() * 256);
		const z = Math.floor(Math.random() * 256);
		return 'rgb(' + x + ',' + y + ',' + z + ')';
	}

	private generateRandomElements() {
		const elementsArray = [];
		for (let i = 0; i < 2000000; i++) {
			elementsArray.push(<div
				style={{
					background: this.random_bg_color(),
					height: Math.floor(Math.random() * (800 - 200 + 1)) + 200 + 'px',
					width: Math.floor(Math.random() * (800 - 200 + 1)) + 200 + 'px'
				}}>
				{`element ${i}`}
			</div>);
		}
		return elementsArray;
	}

	render() {
		return <>
			<TS_VirtualizedList itemHeight={100} className={'custom-scrollbar__vertical'} listToRender={this.generateRandomElements()}/>
		</>;
	}
}

export const PgDev_VirtualizedElements: AppToolsScreen = {
	name: 'Virtualized Elements',
	key: 'virtualized-elements',

	renderer: VirtualizedElements
};