import * as React from 'react';
import {AppToolsScreen, ComponentSync, TS_Table} from '@nu-art/thunderstorm/frontend';

type Row = {
	col1: number;
	col2: number;
	col3: number;
	col4: number;
}

class PgDev_TS_Table_Class
	extends ComponentSync {

	protected deriveStateFromProps(nextProps: any) {
		return;
	}

	render() {

		const rows: Row[] = new Array(10).fill(0).map((item, i) => {
			return {col1: i, col2: i, col3: i, col4: i};
		});

		return <TS_Table
			rows={rows}
			header={['col1', 'col2', 'col3', 'col4']}
			headerRenderer={item => <div>{item}</div>}
			cellRenderer={(prop, item, index) => <div>{prop} - cell {index}</div>}
			tr={(r, i) => ({
				className: i === 3 ? 'selected' : undefined,
			})}
		/>;
	}
}

export const PgDev_TS_Table: AppToolsScreen = {name: 'TS_Table', key: 'tables-tests', renderer: PgDev_TS_Table_Class};