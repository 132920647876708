import * as React from 'react';
import {ComponentSync, EditableDBItemV3, EditableItem, LL_H_C, LL_V_L, TS_Button} from '@nu-art/thunderstorm/frontend';
import {DB_Account} from '@nu-art/user-account';
import {generateUUID, MUSTNeverHappenException} from '@nu-art/ts-common';
import './EditorWrapper_Accounts.scss';
import {Dialog_GenerateToken} from '@km/managers/Manager_Permissions/dialogs/generate-token/Dialog_GenerateToken';
import {Component_UserPermissions} from './Component_UserPermissions';
import {
    Component_ManagerSubHeader
} from '@app/hcs/frontend/ui/shared-components/Component_ManagerSubHeader/Component_ManagerSubHeader';
import {DB_PermissionUser, ModuleFE_PermissionUser, UI_PermissionUser} from '@nu-art/permissions/frontend';
import {UI_User} from '@app/org/_entity/user/shared';
import {ModuleFE_User} from '@app/org/_entity/user/frontend/ModuleFE_User';


type Props = {
    account: DB_Account,
    isPreview?: boolean
};

type State = {
    account: DB_Account;
    user: EditableItem<UI_User>;
    permissionUser: EditableItem<UI_PermissionUser>;
    isPreview?: boolean
};

export class EditorWrapper_Accounts
    extends ComponentSync<Props, State> {

    // ######################## Lifecycle ########################

    protected deriveStateFromProps(nextProps: Props, state: State) {
        const user = ModuleFE_User.cache.unique(nextProps.account._id);
        if (!user)
            throw new MUSTNeverHappenException(`Could not get user for id ${nextProps.account._id}`);

        state.user = new EditableDBItemV3(user, ModuleFE_User);
        const permissionUser = ModuleFE_PermissionUser.cache.unique(nextProps.account!._id);
        if (!permissionUser)
            throw new MUSTNeverHappenException(`Could not get permission user for id ${nextProps.account!._id}`);

        // Need to make sure to create new editable instance only when it's needed
        if ((nextProps.account._id !== state?.account?._id) || !state.permissionUser)
            state.permissionUser = this.getEditablePermissionUser(permissionUser);

        state.account = nextProps.account;
        state.isPreview = nextProps.isPreview;
        return state;
    }

    // ######################## Logic ########################

    private getEditablePermissionUser(permissionUser: Partial<DB_PermissionUser>) {
        return new EditableDBItemV3(permissionUser, ModuleFE_PermissionUser).setAutoSave(true).setOnChanged(async editable => this.reDeriveState({permissionUser: editable}));
    }

    private save = async () => {
        await this.state.user.save();
        await this.state.permissionUser.save();
        this.forceUpdate();
    };

    private getHeaderButtons = () => {
        if (!this.state.account)
            return [];

        const buttons = [
            <TS_Button onClick={this.save} key={generateUUID()}>Save</TS_Button>,
        ];

        if (this.state.account?.type === 'service')
            buttons.push(<TS_Button onClick={() => Dialog_GenerateToken.show(this.state.account?._id!)}
                                    key={generateUUID()}>GENERATE
                TOKEN</TS_Button>);

        return buttons;
    };

    // ######################## Render ########################

    private renderHeader = () => {
        if (!this.state.isPreview)
            return <Component_ManagerSubHeader
                buttons={[]}/>;

        return <Component_ManagerSubHeader
            buttons={this.getHeaderButtons()}/>;
    };

    private renderAccountDetails = () => {
        if (!this.state.account)
            return <></>;

        return <LL_H_C className={'account-details'}>
            <div>{this.state.account.email}</div>
            <div>({this.state.account.type})</div>
        </LL_H_C>;
    };

    private renderBody = () => {
        return <LL_V_L className={'user-viewer__body'}>
            {this.renderAccountDetails()}
            <Component_UserPermissions user={this.state.user} editable={this.state.permissionUser}/>
        </LL_V_L>;
    };

    render() {
        return <LL_V_L className={'user-viewer'}>
            {this.renderHeader()}
            {this.renderBody()}
        </LL_V_L>;
    }
}