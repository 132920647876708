import {AppToolsScreen, ComponentSync, LL_V_L, TS_DragAndDrop} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';


class PgComponent
	extends ComponentSync {
	deriveStateFromProps() {

	}

	render() {
		return <LL_V_L>
			<TS_DragAndDrop
				validate={TS_DragAndDrop.validateFilesBySuffix('js')}
				onChange={(acceptedFiles: File[], rejectedFiles: File[]) => {
					this.logInfo(acceptedFiles, rejectedFiles);
				}}/>
		</LL_V_L>;
	}
}

export const PgDev_DragAndDrop: AppToolsScreen = {name: 'PG - DragAndDrop', key: 'drag-and-drop', renderer: PgComponent};
