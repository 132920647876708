import * as React from 'react';
import {AppToolsScreen, ComponentSync, TS_ProgressBar} from '@nu-art/thunderstorm/frontend';
import './PgDev_ProgressBar.scss';

type State = {
	ratio: number;
}

class PgDev_ProgressBar_Class extends ComponentSync<{}, State> {

	protected deriveStateFromProps(nextProps: any, state?: Partial<any> | undefined) {
		return {ratio: 0};
	}

	componentDidMount() {
		setInterval(() => {
			let ratio = this.state.ratio + 0.01;
			if (ratio > 1)
				ratio = 0;
			this.setState({ratio});
		}, 50);
	}


	render() {
		return <div id={'pg-dev-progress-bar'}>
			<TS_ProgressBar ratios={[this.state.ratio]}/>
			<TS_ProgressBar ratios={[this.state.ratio]} type={'radial'}/>
			{/*<ComponentAwaitAll/>*/}
		</div>;
	}
}

export const PgDev_ProgressBar: AppToolsScreen = {
	name: 'Progress Bar Test',
	key: 'progress-bar-test',
	renderer: PgDev_ProgressBar_Class
};