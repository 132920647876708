import * as React from 'react';
import {_className, ComponentSync, LL_H_C, LL_V_L, TS_Input, AppToolsScreen} from '@nu-art/thunderstorm/frontend';
import './PgDev_InputsTest.scss';

type State = {
	inputValue?: string;
	controlledInputAcceptedValue?: string;
	uncontrolledInputAcceptedValue?: string;
	controlledInputOnCancel: boolean;
	uncontrolledInputOnCancel: boolean;
};

class PgDev_InputsTest_Class
	extends ComponentSync<{}, State> {

	protected deriveStateFromProps(nextProps: any): State {
		return {
			controlledInputOnCancel: false,
			uncontrolledInputOnCancel: false,
		};
	}

	private handleOnChange = (value: string) => {
		this.setState({inputValue: value});
	};

	render() {
		return <LL_H_C className={'inputs-test'}>
			<div>
				<LL_V_L>
					<h2>Controlled Input</h2>
					<TS_Input
						type={'text'}
						value={this.state?.inputValue || ''}
						onChange={this.handleOnChange}
						onAccept={value => this.setState({controlledInputAcceptedValue: value})}
						onCancel={() => {
							this.setState({controlledInputOnCancel: true},
								() => setTimeout(() => this.setState({controlledInputOnCancel: false}), 500)
							);
						}}
					/>
				</LL_V_L>
				<LL_V_L>
					<div className={_className('oncancel-indicator', (this.state.controlledInputOnCancel ? 'active' : undefined))}>onCancel</div>
				</LL_V_L>
				<LL_V_L>
					<h2>onAccept data</h2>
					<div>{this.state?.controlledInputAcceptedValue}</div>
				</LL_V_L>
			</div>
			<div>
				<LL_V_L>
					<h2>Uncontrolled Input</h2>
					<TS_Input
						type={'text'}
						onAccept={value => this.setState({uncontrolledInputAcceptedValue: value})}
						onCancel={() => {
							this.setState({uncontrolledInputOnCancel: true},
								() => setTimeout(() => this.setState({uncontrolledInputOnCancel: false}), 500)
							);
						}}
					/>
				</LL_V_L>
				<LL_V_L>
					<div className={_className('oncancel-indicator', (this.state.uncontrolledInputOnCancel ? 'active' : undefined))}>onCancel</div>
				</LL_V_L>
				<LL_V_L>
					<h2>onAccept data</h2>
					<div>{this.state?.uncontrolledInputAcceptedValue}</div>
				</LL_V_L>
			</div>
			{/*<TS_BusyButton onClick={async () => {*/}
			{/*	const values = await ModuleFE_Variable.queryReduce((accumulator, arrayItem, index, array) => {*/}
			{/*		return accumulator + arrayItem.name + ' ';*/}
			{/*	}, '', (item) => item.name.includes('z'));*/}
			{/*	console.log(values);*/}
			{/*}}>Click Test</TS_BusyButton>*/}
		</LL_H_C>;
	}
}

export const PgDev_InputsTest: AppToolsScreen = {name: 'Inputs Test', key: 'input-tests', renderer: PgDev_InputsTest_Class};