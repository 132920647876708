import {PanelConfig} from '@nu-art/ts-workspace/frontend';

export const WorkspaceConfig_DiseaseProfile_ChiefComplaint: PanelConfig<'horizontalSpace'> = {
	name: 'Disease Property Data',
	key: 'horizontalSpace',
	visible: true,
	factor: 1,
	min: -1,
	data: {
		panels: [
			{
				name: 'leftPanel', key: 'chiefComplaintVariables', visible: true, factor: 1 / 2, min: 200,
				data: {}
			},
			{
				name: 'rightPanel', key: 'chiefComplaintValues', visible: true, factor: 1 / 2, min: 200,
				data: {}
			}
		]
	}
};