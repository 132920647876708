import * as React from 'react';
import {_className, ComponentSync, LL_H_C, LL_V_C, LL_V_L, AppToolsScreen} from '@nu-art/thunderstorm/frontend';
import './PgDev_Palette.scss';

const dummyArr = [1, 2, 3, 4, 5, 6, 7];

class PgDev_Palette_Renderer
	extends ComponentSync {

	//######################### Life Cycle #########################

	protected deriveStateFromProps(nextProps: any) {
		return;
	}

	//######################### Logic #########################

	private rgb2hex = (rgb: string) => {
		if (!rgb)
			return '';

		const color = `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)?.splice(1)
			.map(n => parseInt(n, 10).toString(16).padStart(2, '0'))
			.join('').toUpperCase()}`;

		return color !== '#undefined' ? color : '';
	};

	private isLight = (color: any): boolean => {
		// Variables for red, green, blue values
		let r, g, b;

		// Check the format of the color, HEX or RGB?
		if (color.match(/^rgb/)) {

			// If RGB --> store the red, green, blue values in separate variables
			color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

			r = color[1];
			g = color[2];
			b = color[3];
		} else {

			// If hex --> Convert it to RGB: http://gist.github.com/983661
			color = +('0x' + color.slice(1).replace(
				color.length < 5 && /./g, '$&$&'));

			r = color >> 16;
			g = color >> 8 & 255;
			b = color & 255;
		}

		// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
		const hsp = Math.sqrt(
			0.299 * (r * r) +
			0.587 * (g * g) +
			0.114 * (b * b)
		);

		// Using the HSP value, determine whether the color is light or dark
		if (hsp > 170)
			return true;
		return false;
	};

	private setColorTexts = () => {
		const elements = document.querySelectorAll('.square');
		//First Load
		if (!elements.length) {
			this.forceUpdate();
		}
		elements.forEach(element => {
			const color = window.getComputedStyle(element).getPropertyValue('background-color');
			const textColor = this.isLight(color) ? 'black' : 'white';
			element.innerHTML = element.innerHTML.length ? element.innerHTML + '\n' + this.rgb2hex(color) : this.rgb2hex(color);
			// @ts-ignore
			element.style.color = textColor;
		});
	};

	//######################### Render #########################

	private renderSquare = (classname: string, i?: number) => {
		const boxClassName = _className('square', classname);
		if (!i)
			return <div className={boxClassName}/>;

		const str = i === 1 ? 'Base' : `(${i})`;
		return <div className={boxClassName}>{str}</div>;
	};

	private renderWarms = () => {
		return <LL_V_C className={'warm-container'}>
			<h1>Warms</h1>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('pink-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('ochre-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('orange-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('red-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('monochromatic-' + n, n))}</div>
		</LL_V_C>;
	};

	private renderColds = () => {
		return <LL_V_C className={'cold-container'}>
			<h1>Colds</h1>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('black-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('gray-blue-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('dark-blue-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('dark-green-' + n, n))}</div>
			<div className={'color-row'}>{dummyArr.map(n => this.renderSquare('purple-' + n, n))}</div>
		</LL_V_C>;
	};

	private renderGradiants = () => {
		return <LL_V_C className={'gradient-container'}>
			<h1>Gradients</h1>
			<div className={'gradients'}>
				{this.renderSquare('blue-gradient')}
				{this.renderSquare('green-gradient')}
				{this.renderSquare('orange-gradient')}
				{this.renderSquare('blue-inverted-gradient')}
				{this.renderSquare('blue--gray-gradient')}
				{this.renderSquare('gray-gradient')}
			</div>
		</LL_V_C>;
	};

	private renderUniques = () => {
		return <LL_V_C className={'unique-container'}>
			<h1>Uniques</h1>
			<div className={'uniques'}>
				{this.renderSquare('unique-green')}
				{this.renderSquare('unique-orange')}
				{this.renderSquare('unique-light_blue')}
				{this.renderSquare('unique-red')}
				{this.renderSquare('unique-glowing_red')}
				{this.renderSquare('unique-turquoise')}
				{this.renderSquare('unique-green_select')}
				{this.renderSquare('unique-light_blue_select')}
			</div>
		</LL_V_C>;
	};

	render() {
		this.setColorTexts();
		return <LL_V_L className={'palette-container'}>
			<LL_H_C className={'palette-container__row'}>
				{this.renderColds()}
				{this.renderWarms()}
			</LL_H_C>
			{this.renderGradiants()}
			{this.renderUniques()}
		</LL_V_L>;
	}
}

export const PgDev_Palette: AppToolsScreen = {name: 'Palette Test', key: 'palette-test', renderer: PgDev_Palette_Renderer};

