//MedicationStatement - patient states they are taking a medication

import {QUAI_FHIR_Request} from '@app/fhir';


export const Patient_CamilaLopez: QUAI_FHIR_Request = {
	fhirClientId: 'sandbox',
	name: 'Camila Lopez',
	patientFhirId: 'erXuFYUfucBZaryVksYEcMg3',
	protocol: 'R4',
	// resources: [
	// 	{
	// 		resource: 'DiagnosticReport',
	// 		params: {patient: '${patientFhirId}'}
	// 	},
	//
	// 	{
	// 		'resource': 'Observation/efdH9KfkpL80Id0L6JfDK5LO-yyDW4ziEBQtZ3.RMbNjen0QdebiGe502pCPQ4-9I3',
	// 		params: {}
	//
	// 	},
	// 	{
	// 		'resource': 'Observation/efdH9KfkpL80Id0L6JfDK5LO-yyDW4ziEBQtZ3.RMbNiZYMaZC4SMjWZa2lcoygsB3',
	// 		params: {}
	//
	// 	},
	// 	{
	// 		'resource': 'Observation/efdH9KfkpL80Id0L6JfDK5LO-yyDW4ziEBQtZ3.RMbNjuRJ.TpTgtETGg6K0LfRGG3',
	// 		params: {}
	//
	// 	}
	// 	,
	// 	{
	// 		resource: 'List',
	// 		params: {patient: '${patientFhirId}', code: 'medications'}
	// 	},
	// 	{
	// 		skip: true,
	// 		resource: 'Medication',
	// 		params: {patient: '${patientFhirId}'}
	// 	},
	// 	{
	// 		resource: 'MedicationRequest',
	// 		params: {patient: '${patientFhirId}'}
	// 	},
	// 	{
	// 		resource: 'Observation/${observationId}',
	// 		params: {patient: '${patientFhirId}'}
	// 	},
	// 	{
	// 		resource: 'Patient/${patientFhirId}',
	// 		params: {}
	// 	},
	// 	{
	// 		resource: 'Procedure',
	// 		params: {patient: '${patientFhirId}', /*date: ['gt2019-01-01', 'lt2019-02-20']*/}
	// 	},
	// 	{
	// 		resource: 'Specimen/${specimenId}',
	// 		params: {}
	// 	},
	// 	{
	// 		resource: 'ServiceRequest/eK6sCsSKqfbW3Mps-9UTttzTXimr.hD8JVMCVQTY8yMo3',
	// 		params: {}
	// 	},
	// ]
};

export const Patients_Sandbox = [
	Patient_CamilaLopez
];