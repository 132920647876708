export const config = {
  "label": "dev",
  "ModuleFE_Thunderstorm": {
    "appName": "app-km-frontend - (dev)"
  },
  "ModuleFE_XHR": {
    "origin": "https://knowledge-manager-backend.dev.quai.md",
    "timeout": 30000,
    "compress": false,
    "minLogLevel": false
  },
  "ModuleFE_FirebaseListener": {
    "firebaseConfig": {
      "apiKey": "AIzaSyDl400P2KJBFmk_bUmD4uFEbp7NuuGjD1U",
      "authDomain": "quai-md-km-dev.firebaseapp.com",
      "databaseURL": "https://quai-md-km-dev-default-rtdb.firebaseio.com",
      "projectId": "quai-md-km-dev",
      "storageBucket": "quai-md-km-dev.appspot.com",
      "messagingSenderId": "88975003664",
      "appId": "1:88975003664:web:51d3643cf1d10b87d9ba95",
      "measurementId": "G-BL43693XX1"
    }
  }
};