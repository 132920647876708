import {PanelConfig} from '@nu-art/ts-workspace/frontend';

export const WorkspaceKey_Permissions = 'permission-manager';

export const WorkspaceConfig_PermissionsEditor: PanelConfig<'horizontalSpace'> = {
	name: WorkspaceKey_Permissions,
	key: 'horizontalSpace',
	visible: true,
	factor: 1,
	min: -1,
	data: {
		panels: [
			{
				name: 'LeftPanel', key: 'selector', visible: true, factor: 1.5 / 5, min: 450,
				data: {}
			},
			{
				name: 'RightPanel', key: 'presenter', visible: true, factor: 3.5 / 5, min: 1100,
				data: {}
			},
		]
	}
};
