import {AppToolsScreen, ComponentSync, LL_H_C} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';
import './PgDev_DraggableTable.scss';

type Props = {}

type Row = {
	cellOne: string;
	cellTwo: string;
	cellThree: string;
}

type State = {
	data: Row[]
}

class PgDev_DraggableTable_class
	extends ComponentSync<Props, State> {

	private draggedItemIndex: { current: number | null } = {current: null};
	private draggedOverItemIndex: { current: number | null } = {current: null};

	protected deriveStateFromProps(nextProps: Props): State {
		const state: State = this.state || {
			data: [
				{
					cellOne: '1-1',
					cellTwo: '1-2',
					cellThree: '1-3',
				}, {
					cellOne: '2-1',
					cellTwo: '2-2',
					cellThree: '2-3',
				}, {
					cellOne: '3-1',
					cellTwo: '3-2',
					cellThree: '3-3',
				}, {
					cellOne: '4-1',
					cellTwo: '4-2',
					cellThree: '4-3',
				},
			]
		};

		return state;
	}

	//Logic
	onDragStart = (e: React.DragEvent<HTMLElement>, rowIndex: number) => {
		this.draggedItemIndex.current = rowIndex;
	};


	onDragEnter = (e: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number) => {
		if (this.draggedItemIndex.current === null)
			return;

		this.draggedOverItemIndex.current = rowIndex;
		const listCopy = [...this.state.data];
		const draggingItemContent = listCopy[this.draggedItemIndex.current];

		listCopy.splice(this.draggedItemIndex.current, 1);
		listCopy.splice(this.draggedOverItemIndex.current, 0, draggingItemContent);

		this.draggedItemIndex.current = this.draggedOverItemIndex.current;
		this.draggedOverItemIndex.current = null;
		this.setState({data: listCopy});
	};

	render() {
		return <LL_H_C>
			<div className={'draggable-table-container'}>
				<table className={'draggable-table'}>
					<thead>
					<tr>
						<th>col1</th>
						<th>col2</th>
						<th>col3</th>
					</tr>
					</thead>
					<tbody>
					{this.state.data.map((row: Row, rowIndex) => {
						return <tr
							key={rowIndex}
							draggable={'true'}
							onDragStart={(e) => this.onDragStart(e, rowIndex)}
							onDragOver={(e) => e.preventDefault()}
							onDragEnter={(e) => this.onDragEnter(e, rowIndex)}
						>
							<td>{row.cellOne}</td>
							<td>{row.cellTwo}</td>
							<td>{row.cellThree}</td>
						</tr>;
					})}
					</tbody>
				</table>
			</div>
			<div>
				{this.state.data.map((item, i) => {
					return <div
						draggable={'true'}
						onDragStart={(e) => this.onDragStart(e, i)}
						onDragEnter={(e) => this.onDragEnter(e, i)}
						onDragOver={(e) => e.preventDefault()}
					>{item.cellOne}</div>;
				})}
			</div>
		</LL_H_C>;
	}
}

export const PgDev_DraggableTable: AppToolsScreen = {name: 'Draggable Table', key: 'draggable-table', renderer: PgDev_DraggableTable_class};