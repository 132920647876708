import * as React from 'react';
import {AppToolsScreen, ComponentSync, TS_ErrorBoundary} from '@nu-art/thunderstorm/frontend';
import {BadImplementationException} from '@nu-art/ts-common';


class PgDev_ErrorBoundary_Class
	extends ComponentSync<{}> {

	protected deriveStateFromProps(nextProps: any): undefined {
		return;
	}

	render() {
		return <TS_ErrorBoundary>
			<Renderer/>
		</TS_ErrorBoundary>;
	}
}

const Renderer = () => {
	throw new BadImplementationException('This is an error');
	return <div></div>;
};

export const PgDev_ErrorBoundary: AppToolsScreen = {name: 'Error Boundary', key: 'error-boundary', renderer: PgDev_ErrorBoundary_Class};
