import {PanelConfig} from '@nu-art/ts-workspace/frontend';


export const WorkspaceKey_ExpressionSearch = 'workspace-key--expression-search';
export const WorkspaceConfig_ExpressionSearch: PanelConfig<'horizontalSpace'> = {
	name: 'expression-search',
	key: 'horizontalSpace',
	visible: true,
	factor: 1,
	min: -1,
	data: {
		panels: [
			{
				name: 'LeftPanel', key: 'searchPanel', visible: true, factor: 3.5 / 5, min: 500,
				data: {}
			},
			{
				name: 'RightPanel', key: 'dataViewer', visible: true, factor: 1.5 / 5, min: 500,
				data: {}
			},
		]
	}
};
