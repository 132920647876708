import * as React from 'react';
import {
	AppToolsScreen,
	ComponentSync,
	Coordinates,
	MenuBuilder,
	openContent,
	SimpleListAdapter,
	TS_Button
} from '@nu-art/thunderstorm/frontend';
import './PgDev_PopUpTest.scss';

const menuOptions = ['Option 1', 'Option 2', 'Option 3'];

class PgDev_PopUpTest_Class
	extends ComponentSync {

	protected deriveStateFromProps(nextProps: any, state?: Partial<any> | undefined) {
		return;
	}

	private getMousePos = (e: React.MouseEvent): Coordinates => {
		return {x: e.clientX, y: e.clientY};
	};

	private openMenuPopUp = (e: React.MouseEvent) => {
		const adapter = SimpleListAdapter(menuOptions, item => <div>{item.item}</div>);
		const id = 'pg-dev__menu-pop-up';
		const pos = this.getMousePos(e);

		new MenuBuilder(adapter, pos, {x: 0, y: 1})
			.setId(id)
			.show();
	};

	render() {
		return <div id={'pg-dev__pop-up-test'}>
			<TS_Button onClick={this.openMenuPopUp}>Open Menu Pop Up</TS_Button>
			<div {...openContent.popUp.bottom('pg-dev__custom-pop-up', () => <div>This is a pop up with custom content!</div>, 10)}>Open Custom Pop Up</div>
		</div>;
	}
}

export const PgDev_PopUpTest: AppToolsScreen = {
	name: 'Pop Up Test',
	key: 'pop-up-test',

	renderer: PgDev_PopUpTest_Class
};