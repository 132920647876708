import * as React from 'react';
import {ComponentSync, getQueryParameter, LL_H_C, ModuleFE_RoutingV2, TS_Button} from '@nu-art/thunderstorm/frontend';
import {UniqueId} from '@nu-art/ts-common';
import './PanelWrapper_ResourceManager.scss';
import {DB_Resource} from '@app/hcs';
import {Dialog_CreateNewResource} from '@km/managers/ResourceEditor/dialogs/Dialog_CreateNewResource';
import {Panel_ResourceManager_V1_old} from '@km/managers/ResourceEditor';
import {Component_SearchAndResults} from '@app/styles/frontend';
import {Page_ResourceEditor_V2} from '@app/hcs/frontend/ui/Page_ResourceEditor/Page_ResourceEditor_V2';

type Props = {}
type State = {
	selectedResult?: UniqueId
}

export class PanelWrapper_ResourceManager
	extends ComponentSync<Props, State> {
	constructor(props: Props) {
		super(props);
	}

	protected deriveStateFromProps(nextProps: Props, state: State) {
		return {selectedResult: getQueryParameter('selectedResult') as string | undefined};
	}

	private onCompleted = <T extends DB_Resource>(item: T) => {
		ModuleFE_RoutingV2.goToRoute(Panel_ResourceManager_V1_old.Route, {selectedResult: item._id});
		this.reDeriveState();
	};

	render() {
		return (
			<div id={'resource-manager-panel'} className={'match_parent'}>
				<LL_H_C className={'button-container'}>
					<TS_Button onClick={() => {
						Dialog_CreateNewResource.show(this.onCompleted);
					}}>
						ADD RESOURCE
					</TS_Button>
				</LL_H_C>
				<Component_SearchAndResults
					searchItems={[Page_ResourceEditor_V2.SearchableItem_Resource]}
					onSearchItemSelected={() => {
					}}
				/>
			</div>
		);
	}
}