import * as React from 'react';
import {LL_V_L} from '@nu-art/thunderstorm/frontend/components/Layouts/Layouts';
import {TS_Button} from '@nu-art/thunderstorm/frontend/components/TS_Button/TS_Button';
import {Digraph, Edge, Node, Subgraph, toDot} from 'ts-graphviz';
import {TypedMap} from '@nu-art/ts-common';
import {graphviz} from 'd3-graphviz';
import {ModuleFE_PathwayState, ModuleFE_PathwayTransition} from '@app/pathway/frontend';
import {AppToolsScreen, ComponentSync} from '@nu-art/thunderstorm/frontend';


type State = {
	dotString?: string;
	digraph?: Digraph;
	ref: SVGSVGElement;
}

class PgComponent
	extends ComponentSync<{}, State> {

	private doSomething = async () => {
		const digraph = this.newGetMeta();
		const dotString = toDot(digraph);
		// this.logInfo(dotString, digraph);
		this.setState({digraph: digraph, dotString: dotString});
		// d3.select(this.state.ref).().renderDot(dotString);
		// d3.select("#graph").graphviz().renderDot(dotString);
		graphviz('#graph').renderDot(dotString);
		// d3.select('#graph')
		// 	.graphviz()
		// 	.renderDot('digraph {a -> b}');
		// d3.select(this.state.ref);
		// // d3.fro;
		// d3.select('.graph');

	};

	render() {
		return <LL_V_L>
			{this.renderButtons()}
			{/*{this.renderMetaPathway()}*/}
			<svg className={'container'} ref={(ref: SVGSVGElement) => this.setState({ref: ref})} width={100}
				 height={100}></svg>
			<div id={'graph'}>a</div>
		</LL_V_L>;
	}

	private renderButtons = () => {
		return <TS_Button onClick={this.doSomething}>Do something</TS_Button>;
	};

	private newGetMeta = () => {
		const states = ModuleFE_PathwayState.cache.allMutable(); // nodes
		this.logInfo(`States: ${states.length}. Transitions: ${ModuleFE_PathwayTransition.cache.all().length}`);
		const pathway = new Digraph();
		const container = new Subgraph('Container');
		pathway.addSubgraph(container);
		const nodeToID: TypedMap<Node> = {};
		states.forEach(state => {
			const node = new Node(state._id, {
				color: 'blue',
				label: state.name,
				// tooltip: '',
			});
			nodeToID[state._id] = node;
			container.addNode(node);
		});

		ModuleFE_PathwayTransition.cache.forEach(transition => {
			if (!ModuleFE_PathwayState.cache.unique(transition.sourceId)) {
				// Some source state id is missing. Skip this transition.
				this.logError('No source id for transition');
				return;
			}
			if (!ModuleFE_PathwayState.cache.unique(transition.targetId)) {
				// Some target state id is missing. Skip this transition.
				this.logError('No target id for transition');
				return;
			}
			//todo currently assume there is only one sourceId and one targetId.
			container.addEdge(new Edge([nodeToID[transition.sourceId], nodeToID[transition.targetId]], {
				color: 'black'
			}));
		});
		return pathway;
	};
}

export const PgDev_GraphviZ: AppToolsScreen = {name: 'Graphviz', key: 'graphviz', renderer: PgComponent};