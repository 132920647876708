import React = require('react');
import {AppToolsScreen, ComponentSync, TS_Slider} from '@nu-art/thunderstorm/frontend';

class PgDev_TS_Slider_Class extends ComponentSync {

	protected deriveStateFromProps(nextProps: any) {
		return;
	}

	render() {
		return <TS_Slider min={0} max={10}/>;
	}
}

export const PgDev_TS_Slider: AppToolsScreen = {name: 'Slider Test', key: 'slider-test', renderer: PgDev_TS_Slider_Class};