import {PanelConfig} from '@nu-art/ts-workspace/frontend';

export const WorkspaceConfig_HealthcareSpace: PanelConfig<'horizontalSpace'> = {
	name: 'Healthcare Space',
	key: 'horizontalSpace',
	visible: true,
	factor: 1,
	min: -1,
	data: {
		panels: [
			{
				name: 'LeftPanel', key: 'categoriesPanel', visible: true, factor: 1 / 5, min: 240,
				data: {
					filter: ''
				}
			},
			{
				name: 'centerPanel', key: 'variablesPanel', visible: true, factor: 1 / 5, min: 300,
				data: {
					filter: '',
					categoryFilter: undefined
				}
			},
			{
				name: 'RightPanel', key: 'variableValuesPanel', visible: true, factor: 3 / 5, min: 300,
				data: {
					filter: '',
				}
			},
		]
	}
};
